// Generated by Framer (7699a62)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, useActiveVariantCallback, useConstant, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Time as TimeDate } from "https://framerusercontent.com/modules/HYcHVPAbe8jLEeU7c4mp/TcQV6SEsl3y6G9Op8tp0/Time.js";
import { Loading } from "https://framerusercontent.com/modules/NQ9LlTfXzHTRhTTi6qMI/5u9VoSaQM7qxLI2scUcH/Loading.js";
const LoadingFonts = getFonts(Loading);
const TimeDateFonts = getFonts(TimeDate);

const cycleOrder = ["hbxQL0rdN", "WZfWm8Dvj"];

const serializationHash = "framer-L5Q84"

const variantClassNames = {hbxQL0rdN: "framer-v-w1qak5", WZfWm8Dvj: "framer-v-5rhmgn"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"Variant 1": "hbxQL0rdN", "Variant 2": "WZfWm8Dvj"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "hbxQL0rdN"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const initialVariant = useConstant(() => variant)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "hbxQL0rdN", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onClicku68epw = activeVariantCallback(async (...args) => {
setVariant("WZfWm8Dvj")
})

const ref1 = React.useRef<HTMLElement>(null)

const isDisplayed = () => {
if (baseVariant === "WZfWm8Dvj") return false
return true
}

const isDisplayed1 = () => {
if (baseVariant === "WZfWm8Dvj") return true
return false
}

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><motion.div {...restProps} animate={variants} className={cx(serializationHash, ...sharedStyleClassNames, "framer-w1qak5", className, classNames)} data-framer-name={"Variant 1"} initial={initialVariant} layoutDependency={layoutDependency} layoutId={"hbxQL0rdN"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{backgroundColor: "rgb(255, 255, 255)", ...style}} {...addPropertyOverrides({WZfWm8Dvj: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}>{isDisplayed() && (<motion.div className={"framer-1pt592n-container"} layoutDependency={layoutDependency} layoutId={"b_Xw3YCHv-container"}><Loading animation={{duration: 1.3, ease: "linear", type: "tween"}} color={"rgb(136, 136, 136)"} duration={2} fadeOut hasDuration={false} height={"100%"} id={"b_Xw3YCHv"} indicator={"Material"} layoutId={"b_Xw3YCHv"} onClick={onClicku68epw} style={{height: "100%", width: "100%"}} width={"100%"}/></motion.div>)}{isDisplayed1() && (<motion.div className={"framer-pujr1x-container"} layoutDependency={layoutDependency} layoutId={"WWw_oiqw1-container"}><TimeDate color={"rgb(153, 153, 153)"} font={{}} height={"100%"} id={"WWw_oiqw1"} layoutId={"WWw_oiqw1"} monthFormat={"long"} outputType={"time"} showMinutes showMonth showSeconds showWeekday showYear tabularFont timeFormat={"24h"} width={"100%"}/></motion.div>)}</motion.div></Transition>
</LayoutGroup>)

});

const css = [".framer-L5Q84[data-border=\"true\"]::after, .framer-L5Q84 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-L5Q84.framer-jql0v7, .framer-L5Q84 .framer-jql0v7 { display: block; }", ".framer-L5Q84.framer-w1qak5 { height: 362px; overflow: hidden; position: relative; width: 410px; }", ".framer-L5Q84 .framer-1pt592n-container { flex: none; height: 40px; left: 135px; position: absolute; top: 103px; width: 40px; }", ".framer-L5Q84 .framer-pujr1x-container { flex: none; height: auto; left: 135px; position: absolute; top: 164px; width: auto; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 362
 * @framerIntrinsicWidth 410
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"WZfWm8Dvj":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FrameriJbgg86Bz: React.ComponentType<Props> = withCSS(Component, css, "framer-L5Q84") as typeof Component;
export default FrameriJbgg86Bz;

FrameriJbgg86Bz.displayName = "k";

FrameriJbgg86Bz.defaultProps = {height: 362, width: 410};

addPropertyControls(FrameriJbgg86Bz, {variant: {options: ["hbxQL0rdN", "WZfWm8Dvj"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FrameriJbgg86Bz, [...LoadingFonts, ...TimeDateFonts])